$font: 'Roboto';

@font-face {
    src: url('../../fonts/icomoon.woff') format('woff'),
        url('../../fonts/icomoon.ttf') format('truetype');
    font-style: normal;

    font: {
        family: 'icomoon';
        weight: normal;
    }
}

.ambience_player_wrapper {
    align-items: center;
    justify-content: center;
    display: inline-block;
    width: 100%;
    padding: 9px;
    box-sizing: border-box;

    .ambience_player_container {
        width: 100%;
        display: inline-flex;
        flex-direction: column;

        select {
            display: inline-block;
            width: 140px;
            padding: 10px;
        }

        .volumeBar {
            width: 300px;
            display: inline-block;
            cursor: pointer;
        }

        input[type="range"] {
            -webkit-appearance: none;
            margin-right: 16px;
            margin-left: 16px;
            width: 240px;
            height: 7px;
            background: rgba(255, 255, 255, 0.6);
            border-radius: 5px;
            background-image: linear-gradient(#fff, #fff);
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background: #fff;
            cursor: ew-resize;
            box-shadow: 0 0 2px 0 #555;
            transition: background .3s ease-in-out;
            cursor: pointer;
        }

        input[type=range]::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
        }
    }

}


.volumebar_container {
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 56px 0px;
    cursor: pointer;
    box-sizing: border-box;

    .volume_icn_container {
        &::before {
            content: '\e907';
            display: inline-block;
            font-family: 'icomoon';
            color: #FFFFFF;
            font-size: 24px;
        }
    }
}


.ambience_img_wrapper {
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    .ambience_ul {
        // width: 100%;
        overflow-x: auto;
        padding: 0px;
        margin: auto;
        display: inline-flex;
        align-items: center;
        // flex-basis: min-content;

        .ambience_li {
            flex-grow: 1;
            flex-shrink: 1;
            display: inline-flex;
            margin: auto;
            justify-content: center;
            flex-direction: column;

            &.active {
                margin-top: 16px;
            }

            .ambience_title {
                display: inline-flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                font-family: $font;
                letter-spacing: 0.2px;
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                text-align: center;
                color: #FFFFFF;
            }

        }
    }

    .ambience_img_container {
        display: inline-flex;
        padding: 16px;
        flex-direction: column;
        box-sizing: border-box;

        // &.active {
        //     width: 114px;
        //     height: 118px;
        // }

        .ambience_img {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 80px;
            width: 80px;
            border-radius: 50%;
            overflow: hidden;
            flex-direction: column;
            color: #ffffff;
            border: 4px solid #AB7DA9;
            background-color: rgba(255, 255, 255, 0.4);
            cursor: pointer;

            &.active {
                border: 4px solid #FFFFFF;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
            }

            img {
                display: inline-block;
                height: 100%;
                width: 100%;
                z-index: 2;

                &.potrait {
                    width: 100%;
                    height: auto;
                }

                &.landscape {
                    width: auto;
                    height: 100%;
                }

                &.square {
                    height: 100%;
                    width: 100%;
                }
            }
        }

    }
}

.ambience_close_container {
    display: inline-flex;
    width: 100%;
    padding-top: 64px;
    box-sizing: border-box;
    justify-content: center;

    &.playback {
        padding-top: 8px;
    }

    .ambience_close {
        &::before {
            content: '\e918';
            display: inline-block;
            font-family: 'icomoon';
            color: #FFFFFF;
            font-size: 24px;
            cursor: pointer;
        }
    }

}



@media screen and (max-width: 1240px) {
    .ambience_player_wrapper .ambience_player_container input[type=range] {
        width: 190px;
    }

    .ambience_img_wrapper .ambience_img_container .ambience_img {
        width: 68px;
        height: 68px;
    }
}

@media screen and (max-width: 750px) {
    .ambience_player_wrapper .ambience_player_container input[type=range] {
        width: 160px;
    }

}

@media screen and (max-width: 460px) {
    .ambience_player_wrapper .ambience_player_container input[type=range] {
        width: 120px;
    }



}

@media screen and (max-width: 390px) {
    .ambience_player_wrapper .ambience_player_container input[type=range] {
        width: 100px;
    }


}

@media screen and (max-width: 340px) {
    .ambience_player_wrapper .ambience_player_container input[type=range] {
        width: 80px;
    }


}